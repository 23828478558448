import { useContext, useMemo } from "react";
import { UserContext } from "./UserContext";
import axios from "axios";

let baseUrl;

if (process.env.REACT_APP_IS_STAGING === "true") {
  baseUrl = "https://api-staging.compte-rendu.fr/";
} else if (process.env.NODE_ENV === "production") {
  baseUrl = "https://api-clients.compte-rendu.fr/";
} else {
  baseUrl = "http://localhost:8000";
}

export const backendUrl = baseUrl;

// Créer une instance Axios à l'extérieur du hook
const api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// Variable pour suivre si les intercepteurs ont été installés
let interceptorsInstalled = false;

export function useApi() {
  const { setUser, user } = useContext(UserContext);

  // Installer les intercepteurs une seule fois
  useMemo(() => {
    if (!interceptorsInstalled) {
      // Nettoyer les anciens intercepteurs si nécessaire
      api.interceptors.request.clear();
      api.interceptors.response.clear();

      // Ajouter l'intercepteur de requête
      api.interceptors.request.use((config) => {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        if (user && user.accessToken) {
          config.headers["Authorization"] = `Bearer ${user.accessToken}`;
        }
        return config;
      });

      // Ajouter l'intercepteur de réponse
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 401 && user) {
            localStorage.removeItem("user");
            setUser(null);
            window.location.reload();
          }
          return Promise.reject(error);
        }
      );

      interceptorsInstalled = true;
    }
  }, [setUser, user]);

  return api;
}

export function resetApiInstance() {
  interceptorsInstalled = false;
  api.interceptors.request.clear();
  api.interceptors.response.clear();
}
